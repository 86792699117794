body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* LR0.css */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
  color: #333;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.main {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  text-align: center;
  margin-top: 20px;
  row-gap: 20px ;
}

@media screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}


.terms{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.terms a {
  color: #4D4D4D;
}

.input-container {
  margin-bottom: 20px;
}

.input-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.input-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.input-button {
  display: block;
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #0052AD;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.input-button:hover {
  background-color: #0056b3;
}

.output-container {
  margin-bottom: 20px;
}

.output-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.output-textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
}

.lr-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-row {
  display: flex;
  width: 100%;
}

.table-cell {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
}

@media (max-width: 768px) {
  .table-cell {
    font-size: 12px;
  }
}


.dfa-output {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.item {
  margin-bottom: 20px;
}

.item-header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-weight: bold;
}

.item-line {
  display: flex;
  align-items: center;
  padding: 5px;
}

.item-line-content {
  flex-grow: 1;
}

.item-line-arrow {
  font-weight: bold;
  padding-left: 10px;
  color: #888;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.error-message {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-family: Arial, sans-serif;
  color: #333;
}

.error-message i {
  font-size: 24px;
  color: #ff0000;
}

.error-message p {
  font-size: 16px;
  margin: 0;
}

.divider {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 24px 0;
}

.description {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 40px;
}


/* Instruction Section Styling */
.instruction-section {
  border-radius: 5px;
  padding: 40px;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: left;
}

ol {
  padding-left: 1.5rem;
}

h3 {
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;

}
.instruction-section li {
  margin: 5px 0;
  
}
